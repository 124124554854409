import * as React from "react"
import { useLocation } from "@reach/router"

import { TagManagerContext } from "../../core/contexts/tagManager"
import Layout from "../../components/layout/layout"
import Seo from "../../components/seo/seo"
import Divider from "../../components/divider/divider"
import Card from "../../components/card/card"
import ElevatedButton from "../../components/buttons/elevatedButton"

const description =
  "Every digitally-transformed business had to start somewhere. A strategy sets the direction and establishes priorities for your business. It defines your business' view of success and prioritizes the activities that will make this view your reality."

const StrategyPage = () => {
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  return (
    <Layout>
      <Seo title="Services | Strategy" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Strategy</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5 mw-800">
          <h6>Customer &amp; Market Research</h6>
          <p>
            We conduct many types of user research, from usability testing and
            focus groups to surveys and A/B testing. As a critical part of
            iPhupha Digital’s business strategy offering, we employ market
            research to connect with users to simplify their interactions with
            an organization. We design the user experience to be more inspiring,
            connected, and useful for everyone.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Visual Identity &amp; Branding</h6>
          <p>
            Influential brands will stand the test of time, adapt to the market
            needs and changes, and contribute to move the organization faster
            and more efficiently. We always aim to create coherent design
            systems that communicate your company&apos;s values and beliefs with
            clear frameworks and rules.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Customer Experience Strategy</h6>
          <p>
            We understand the role emotion plays within your customer&apos;s
            journey. Emotionally engaged customers are at least three times more
            likely to recommend your product or service and they are three times
            more likely to re-purchase. We craft strategies to help you leverage
            and improve this critical customer link.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Digital Transformation Strategy</h6>
          <p>
            Working side-by-side with leaders from every part of our clients’
            organizations, we help them transform their teams and internal
            operations for success in the digital future. We understand the
            profound and accelerating affect transformation can have on a
            business- activities, processes, competencies and models. We use
            these broader changes and opportunities within digital technologies
            to impact companies in a strategic and prioritized way.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Data Analytics &amp; MVT</h6>
          <p>
            The best user experience advances come from coupling both data
            analytics and Multivariate Testing within a targeted optimization
            program. iPhupha Digital tests concepts developed from primary user
            research, industry best practices and creative imagination. Simply
            put: we imagine great experiences and put them to the test. Discover
            the biggest conversion gains and which specific elements drive this
            success.
          </p>
          <div className="mw-600 mt-5 mb-5">
            <Divider />
          </div>
          <h6>Digital Marketing Strategy</h6>
          <p>
            In the modern marketing landscape a big part of your marketing
            strategy needs to be digital. Your customers are almost always
            online, and you want to be able to reach them. We set plans of
            action to achieve a set of desired business goals. It may be more
            leads for your sales team, it may be more brand awareness in a
            crowded marketplace. Depending on the size of your business and the
            number of moving parts a good digital strategy will help you stay
            focused on your goals and make them achievable.
          </p>
        </div>
      </section>
      <div className="container">
        <Divider />
      </div>
      <section className="container pt-5 pb-5">
        <Card isPrimary={true} canHover={false}>
          <div className="pt-2 pb-2">
            <div className="row justify-space-between align-center">
              <h6 className="mb-3">How can we help you?</h6>
              <span className="text-black">
                <div
                  onClick={() =>
                    onLinkClick({
                      label: "Get in touch with us",
                      href: "/contact-us",
                    })
                  }
                >
                  <ElevatedButton
                    label="Get in touch with us"
                    href="/contact-us"
                  />
                </div>
              </span>
            </div>
          </div>
        </Card>
      </section>
      <div className="container">
        <Divider />
      </div>
    </Layout>
  )
}

export default StrategyPage
